import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../styles/pages/glasses.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GlassesHeading from "../components/GlassesHeading"
import Breadcrumbs from "../components/Breadcrumbs"
import GlassesFilter from "../components/GlassesFilter"
import GlassesList from "../components/GlassesList"
import ProductBanner from "../components/ProductBanner"
import { useDebounce, useGlasses } from "../services/hooks"
import { navigateWithFilters } from "../services/utils"
import CartContext from "../context/cartContext"


const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )

  const right_side_text =
    "Every pair of Hubble’s affordable glasses has impact-resistant lenses, durable barrel hinges, and padded temple tips for extra comfort. Look good, see better—and feel great with our prescription glasses. Simply pick out a pair of glasses that fits your style, input your prescription information, and choose any additional lens coatings. We’ll ship your new pair of prescription glasses directly to your doorstep."
  const list_items = [
    "Our eyeglasses are handcrafted from premium materials to deliver you high-quality frames that are as functional as they are stylish.",
    "Anti-reflective, anti-glare, anti-scratch, hydrophobic, and oleophobic coatings—with the option to add reader magnification and blue light filtering to any pair of eyeglasses.",
    "Hand-assembled and made-to-order by a Hubble team member.",
    "Experience eyewear like never before, with our extensive selection of frames available in every frame size, shape and color you can imagine.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  useEffect(() => {
    if (!debouncedFilter) return

    navigateWithFilters(location.pathname, debouncedFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const schemaMarkup = [
    {
      "@context": "https://schema.org",
      "@url": "https://hubblecontacts.com/glasses",
      "@type": "webpage",
      "name": "Hubble: Buy Glasses Online: Order Prescription Glasses & Frames | Hubble Contacts",
      "description": "Shop affordable prescription glasses online from Hubble, starting at just $49.99. Find your perfect frames from our wide range of styles, colors and frame shapes.",
      "publisher": {
        "@type": "Organization",
        "name": "Hubble Contacts"
      }
    }
  ]

  return (
    <Layout className="glasses">
      <SEO
        title="Hubble: Buy Glasses Online: Order Prescription Glasses & Frames | Hubble Contacts"
        description="Shop affordable prescription glasses online from Hubble, starting at just $49.99. Find your perfect frames from our wide range of styles, colors and frame shapes."
        schemaMarkup={schemaMarkup}
        />
      <GlassesHeading
        color="orange"
        title="Prescription Eyeglasses"
        description="Look good — see better. Shop our stylish, handcrafted eyeglasses online with
                      high-quality prescription lenses that stand up to wear and tear. Get ready to
                      transform your vision with a perfect pair of prescription eyeglasses from Hubble. All prescription glasses always ship FREE right to your door!"
      />

      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"Buy prescription glasses online starting at just $49.99."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
          {/* <FAQBlock faqList={glassesFaq} title="Buy Prescription Glasses Online: Frequently Asked Questions" /> */}
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
